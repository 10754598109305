'use client';
import Link from 'next/link';
import Image from 'next/image';
import clutch from '../../../public/clutupdatednew.webp';
import esomar from '../../../public/ESOMAR_member_RGB-17.webp';
import topupdated from '../../../public/topupatednew.webp';
import FooterPayment from '../../../public/footerImg_payments_2.webp';
import dungs from '../../../public/dungsnew.webp';
import '@/components/footer/footer.scss';
import { jost } from '@/app/fonts/fonts';
import { IsoCerts } from './iso-certs';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { getDictionary } from '@/app/[lang]/dictionaries';

const today = new Date();

const year = today.getFullYear();

export default function Footer() {
  const pathname = usePathname();
  const [footer, setFooter] = useState({
    headings: {
      mail: 'For Business Enquiry :',
      add1: 'Sales Office (U.S.) :',
      add2: 'Asia Pacific Intelligence Center (India) :',
      social: 'Connect With Us :',
      payments: 'Secure Payment By :',
      credibility_certification: 'Credibility and Certifications',
    },
    menu: {
      aboutus: 'About Us',
      industries: 'Industries',
      services: 'Services',
      contactus: 'Contact Us',
      careers: 'Careers',
    },
    reader_club: {
      latest_insights: 'Latest Insights',
      cov_tracker: 'COVID-19 Tracker',
      press_release: 'Press Release',
      info: 'Infographics',
      blogs: 'Blogs',
      news: 'News',
    },
    help: {
      become_reseller: 'Become Reseller',
      how_to_order: 'How to Order?',
      terms: 'Terms and Conditions',
      privacy_policy: 'Privacy Policy',
      disclaimer: 'Disclaimer',
      sitemap: 'Sitemap',
    },
    buttons: {
      home: 'Home',
      email_us: 'Email Us',
      buy_with_discount: 'Buy Now with Special Discount',
      report_snapshot: 'Report Snapshots',
      get_instant_acc: 'Get Instant Access',
    },
  });

  let locales = ['en', 'fr', 'de', 'it', 'ru', 'ja', 'ar', 'zh', 'ko', 'pt'];

  const pathnameHasLocale = locales.some((locale) =>
    pathname.startsWith(`/${locale}/`)
  );

  async function getDict(lang) {
    const dict = await getDictionary(lang);

    const footer = dict.footer;
    //Updating the NavLinks with translated
    setFooter(footer);
  }

  useEffect(() => {
    if (pathnameHasLocale) {
      const locale = pathname.split('/')[1];
      getDict(locale);
    } else {
      getDict('en');
    }
  }, [pathname, pathnameHasLocale]);

  return (
    <>
      <footer>
        <div className="container pt-4">
          <div className="contact-us-main bg-white country-mobile-no">
            <div className="text-start items-center flex flex-col justify-between flex-wrap md:flex md:flex-row">
              <p className="title">{footer.menu.contactus}</p>
              <div className="footitem">
                <span className="footphoneIcons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="bi bi-telephone-inbound-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0z"
                    ></path>
                  </svg>
                </span>
                <p className="title">United States</p>
                <a href="tel:+1-650-918-5898" title="Call Us">
                  +1-650-918-5898
                </a>
              </div>
              <div className="footitem">
                <span className="footphoneIcons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="bi bi-telephone-inbound-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0z"
                    ></path>
                  </svg>
                </span>
                <p className="title">United Kingdom</p>
                <a href="tel:+44-020-8133-4027" title="Call Us">
                  +44-020-8133-4027
                </a>
              </div>
              <div className="footitem">
                <span className="footphoneIcons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="bi bi-telephone-inbound-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0z"
                    ></path>
                  </svg>
                </span>
                <p className="title">Australia</p>
                <a href="tel:+61-2-4786-0457" title="Call Us">
                  +61-2-4786-0457
                </a>
              </div>
              <div className="footitem">
                <span className="footphoneIcons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="bi bi-telephone-inbound-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0z"
                    ></path>
                  </svg>
                </span>
                <p className="title">India</p>
                <a href="tel:+91-848-285-0837" title="Call Us">
                  +91-848-285-0837
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-2">
          <div className="footlinks flex flex-wrap mobile-only">
            <div className="lg:w-4/12 md:w-full w-full">
              <div className="item">
                <h4 className="title mb-0">{footer.headings.mail}</h4>
                <a href="mailto:sales@coherentmi.com" title="Email Us">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-envelope-fill inline-block me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"></path>
                  </svg>{' '}
                  sales@coherentmi.com
                </a>
              </div>
            </div>
            <div className="lg:w-4/12 md:w-full w-full">
              <div className="grid grid-cols-2 md:grid-cols-2 gap-2 md:gap-0 mt-3 mb-3">
                <div className="contact-no">
                  <p className="mb-0 font-13 fontW-600 text-white inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      fill="currentColor"
                      className="bi bi-telephone-outbound me-2 mt-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                    </svg>{' '}
                    United States
                  </p>
                  <Link
                    href="tel:+1-650-918-5898"
                    className=" mb-0 font-13  p-0 inline-block"
                  >
                    +1-650-918-5898
                  </Link>
                </div>

                <div className="contact-no">
                  <p className=" mb-0 font-13 fontW-600 text-white inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      fill="currentColor"
                      className="bi bi-telephone-outbound me-2 mt-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                    </svg>{' '}
                    United Kingdom
                  </p>
                  <Link
                    href="tel:+44-020-8133-4027"
                    title="Call Us"
                    className=" mb-0 font-13 p-0 inline-block "
                  >
                    +44-020-8133-4027
                  </Link>
                </div>

                <div className="contact-no">
                  <p className="mb-0  font-13 fontW-600 text-white inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      fill="currentColor"
                      className="bi bi-telephone-outbound me-4 mt-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                    </svg>{' '}
                    AUS
                  </p>
                  <Link
                    href="tel:+61-2-4786-0457"
                    title="Call Us"
                    className=" mb-0 font-13 p-0 inline-block "
                  >
                    +61-2-4786-0457
                  </Link>
                </div>
                <div className="contact-no">
                  <p className="mb-0  font-13 fontW-600 text-white inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      fill="currentColor"
                      className="bi bi-telephone-outbound me-2 mt-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                    </svg>{' '}
                    India
                  </p>
                  <a
                    href="tel:+91-848-285-0837"
                    title="Call Us"
                    className="mb-0 font-13 p-0 inline-block"
                  >
                    +91-848-285-0837
                  </a>
                </div>
              </div>
            </div>
            <div className="lg:w-4/12 md:w-full w-full topLine">
              <div className="grid grid-cols-3 md:grid-cols-2 gap-5 md:gap-0 mt-3 mb-3">
                <div className="footullinks mt-4">
                  <p className="title mb-0">{footer.headings.menu}</p>
                  <Link href={'/about-us'} title="About Us">
                    {footer.menu.aboutus}
                  </Link>
                  <Link href={'/industries'} title="Industries">
                    {footer.menu.industries}
                  </Link>
                  <Link href={'/services'} title="Services">
                    {footer.menu.services}
                  </Link>
                  <Link href={'/contact-us'} title="Contact Us">
                    {footer.menu.contactus}
                  </Link>
                </div>
                <div className="footullinks mt-4">
                  <p className="title mb-0">{footer.headings.readerclub}</p>
                  <Link href={'/latest-insights'} title="Latest Insights">
                    {footer.reader_club.latest_insights}
                  </Link>
                  <Link href={'/blog'} title="Blogs">
                    {footer.reader_club.latest_insights}
                  </Link>
                </div>
                <div className="footullinks mt-4">
                  <p className="title mb-0">{footer.headings.help}</p>
                  <Link href={'/become-reseller'} title="Become Reseller">
                    {footer.help.become_reseller}
                  </Link>
                  <Link href={'/how-to-order'} title="How To Order?">
                    {footer.help.how_to_order}
                  </Link>
                  <Link href={'/privacy-policy'} title="Privacy Policy">
                    {footer.help.privacy_policy}
                  </Link>
                  <Link
                    href={'/terms-and-conditions'}
                    title="Terms & Condition"
                  >
                    {footer.help.terms}
                  </Link>
                  <Link href={'/disclaimer'} title="Disclaimer">
                    {footer.help.disclaimer}
                  </Link>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-0 mt-3 mb-3 topLine">
                <div className="footcontinfos mt-4">
                  <div className="foot-address">
                    <h5 className="title mb-2">{footer.headings.add1}</h5>
                    <div className="flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        className="bi bi-geo-alt-fill mt-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                      </svg>
                      <address className="text-white">
                        Coherent Market Insights Pvt Ltd, 533 Airport Boulevard,
                        Suite 400, Burlingame, CA 94010, United States
                      </address>
                    </div>
                  </div>
                </div>

                <div className="foot-address mt-md-4">
                  <h6 className="title mb-2 ">{footer.headings.add2}</h6>
                  <div className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-geo-alt-fill mt-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                    </svg>
                    <address className="text-white">
                      Coherent Market Insights Pvt Ltd, 203, 2nd Floor, Bremen
                      Business Center, Aundh, Pune, Maharashtra 411007, India.
                    </address>
                  </div>
                </div>
                <div className="socialmediaMain mt-3 border-bottom-new-reliability topLine">
                  <p className="title mb-2 mt-4">
                    {footer.headings.credibility_certification}
                  </p>
                  <div className="paymentimg flex gap-x-3">
                    <Image
                      src={esomar}
                      className="img-fluid"
                      alt="Credibility and Certifications"
                      title="Credibility and Certifications"
                      sizes="100vw"
                      style={{
                        width: '55px',
                        height: 'auto',
                      }}
                    />
                    <Image
                      src={clutch}
                      className="img-fluid"
                      alt="Credibility and Certifications"
                      title="Credibility and Certifications"
                      sizes="100vw"
                      style={{
                        width: '55px',
                        height: 'auto',
                      }}
                    />
                    <Image
                      src={topupdated}
                      className="img-fluid"
                      alt="Credibility and Certifications"
                      title="Credibility and Certifications"
                      sizes="100vw"
                      style={{
                        width: '55px',
                        height: 'auto',
                      }}
                    />
                  </div>
                </div>
                <div className="socialmediaMain">
                  <p className="title mb-2">{footer.headings.payments}</p>
                  <div className="paymentimg">
                    <Image
                      src={FooterPayment}
                      className="img-fluid"
                      alt="Payment Method"
                      title="Payment Method"
                      width={280}
                      height={50}
                    />
                  </div>
                </div>
                <div className="socialmediaMain socialLinks border-bottom-new-connect topLine">
                  <p className="title mb-2  mt-3">{footer.headings.social}</p>
                  <a
                    className="linkedin"
                    href="https://www.linkedin.com/company/coherent-mi"
                    target="_blank"
                    title="Follow CMI on linkedin"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-linkedin"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
                    </svg>
                  </a>
                  <a
                    className="twitter"
                    href="https://twitter.com/Coherent_MI"
                    target="_blank"
                    title="Follow on twitter"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      className="bi bi-twitter-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"></path>
                    </svg>
                  </a>
                  <a
                    className="facebook"
                    href="https://www.facebook.com/profile.php?id=61553694036602&sk=followers"
                    target="_blank"
                    title="Follow CMI on facebook"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-facebook"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                    </svg>
                  </a>
                  <a
                    className="pinterest"
                    href="https://www.pinterest.com/coherentmiseo/"
                    target="_blank"
                    title="Follow CMI on pinterest"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-pinterest"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0a8 8 0 0 0-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 1 0 8 0z"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid desktop-only mt-5">
          <div className="grid grid-cols-3 gap-4">
            <div className="footlinks grid grid-flow-row">
              <div className="item">
                <h4 className="title mb-0">{footer.headings.mail}</h4>
                <a href="mailto:sales@coherentmi.com" title="Email Us">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-envelope-fill inline-block me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"></path>
                  </svg>{' '}
                  sales@coherentmi.com
                </a>
              </div>
              <div className="footcontinfos mt-4">
                <div className="foot-address">
                  <h5 className=" title mb-2">{footer.headings.add1}</h5>
                  <div className="flex flex-row">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-geo-alt-fill mt-1 "
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                    </svg>
                    <address className={`${jost.className}  w-75`}>
                      Coherent Market Insights Pvt Ltd, 533 Airport Boulevard,
                      Suite 400, Burlingame, CA 94010, United States
                    </address>
                  </div>
                </div>
              </div>
              <div className="foot-address mt-md-4">
                <h6 className="title mb-2">{footer.headings.add2}</h6>
                <div className="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-geo-alt-fill mt-1 "
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                  </svg>

                  <address className={`${jost.className}  w-75`}>
                    Coherent Market Insights Pvt Ltd, 203, 2nd Floor, Bremen
                    Business Center, Aundh, Pune, Maharashtra 411007, India.
                  </address>
                </div>
              </div>
            </div>
            <div className="col-span-2 grid grid-cols-4 gap-4 footlinks">
              <div>
                <div className="footullinks">
                  <p className="title mb-0">{footer.headings.menu}</p>
                  <Link href={'/about-us'} title="About Us">
                    {footer.menu.aboutus}
                  </Link>
                  <Link href={'/industries'} title="Industries">
                    {footer.menu.industries}
                  </Link>
                  <Link href={'/services'} title="Services">
                    {footer.menu.services}
                  </Link>
                  <Link href={'/contact-us'} title="Contact Us">
                    {footer.menu.contactus}
                  </Link>
                </div>
              </div>
              <div className="footullinks">
                <p className=" mb-0 title">{footer.headings.readerclub}</p>
                <Link href={'/latest-insights'} title="Latest Insights">
                  {footer.reader_club.latest_insights}
                </Link>
                {/* <Link href={'/press-releases'} title="Press Release">Press Release</Link> */}
                {/* <Link href={'/news'} title="News">
                  News
                </Link> */}
                <Link href={'/blog'} title="Blogs">
                  {footer.reader_club.blogs}
                </Link>
              </div>
              <div>
                <div className="footullinks">
                  <p className=" mb-0 title">{footer.headings.help}</p>
                  <Link href={'/become-reseller'} title="Become Reselle">
                    {footer.help.become_reseller}
                  </Link>
                  <Link href={'/how-to-order'} title="How To Order?">
                    {footer.help.how_to_order}
                  </Link>
                  <Link href={'/privacy-policy'} title="Privacy Policy">
                    {footer.help.privacy_policy}
                  </Link>
                  <Link
                    href={'/terms-and-conditions'}
                    title="Terms and Condtions"
                  >
                    {footer.help.terms}
                  </Link>
                  <Link href={'/disclaimer'} title="Disclaimer">
                    {footer.help.disclaimer}
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="socialmediaMain">
                  <p className=" mb-0 title">{footer.headings.social}</p>
                  <ul className="list-unstyled flex flex-row justify-between flex-wrap">
                    <li>
                      <a
                        className="linkedin"
                        href="https://www.linkedin.com/company/coherent-mi/"
                        rel="noreferrer"
                        target="_blank"
                        title="Follow CMI on linkedin"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-linkedin"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        className="twitter"
                        href="https://twitter.com/Coherent_MI"
                        target="_blank"
                        rel="noreferrer"
                        title="Follow CMI on twitter"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-twitter"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        className="facebook"
                        href="https://www.facebook.com/profile.php?id=61553694036602&sk=followers"
                        target="_blank"
                        title="Follow CMI on facebook"
                        rel="noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-facebook"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        className="pinterest"
                        href="https://www.pinterest.com/coherentmiseo/"
                        target="_blank"
                        title="Follow CMI on pinterest"
                        rel="noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pinterest"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 0a8 8 0 0 0-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 1 0 8 0z"></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="socialmediaMain mt-2">
                  <p className=" mb-2 title">{footer.headings.payments}</p>
                  <div className="paymentimg">
                    <Image
                      src={FooterPayment}
                      className="img-fluid"
                      alt="Payment Method"
                      title="Payment Method"
                      width={300}
                      height={50}
                    />
                  </div>
                  <IsoCerts />
                </div>
              </div>

              <div className="col-span-4">
                <div className="socialmediaMain mt-3">
                  <p className="mb-2 title">
                    {footer.headings.credibility_certification}
                  </p>
                  <div className="paymentimg flex gap-x-4">
                    <div className="text-center relative">
                      <Image
                        src={dungs}
                        className="img-fluid"
                        alt="Credibility and Certifications"
                        title="Credibility and Certifications"
                        style={{
                          width: '120px',
                          height: 'auto',
                        }}
                      />
                      <span className="text-white text-base absolute start-4 -mt-1">
                        860519526
                      </span>
                    </div>

                    <Image
                      src={esomar}
                      className="img-fluid"
                      alt="Credibility and Certifications"
                      title="Credibility and Certifications"
                      style={{
                        width: '120px',
                        height: 'auto',
                      }}
                    />
                    <Image
                      src={clutch}
                      className="img-fluid"
                      alt="Credibility and Certifications"
                      title="Credibility and Certifications"
                      style={{
                        width: '120px',
                        height: 'auto',
                      }}
                    />
                    <Image
                      src={topupdated}
                      className="img-fluid"
                      alt="Credibility and Certifications"
                      title="Credibility and Certifications"
                      style={{
                        width: '120px',
                        height: 'auto',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyrightxfoot flex flex-row justify-between mt-4">
          <div className="text-white font-medium">
            © {year} CoherentMI. All Rights Reserved.
          </div>
          <div>
            <span className="font-normal text-slate-400">Powered by </span>
            <a
              className="font-bold text-light"
              href="https://www.coherentmarketinsights.com/"
              target="blank"
            >
              Coherent Market Insights Pvt. Ltd.
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}
