'use client';
import '@/components/top-header/top-header.scss';
import { AU, US, GB, IN } from 'country-flag-icons/react/3x2';
import { usePathname } from 'next/navigation';

export default function TopHeader() {
  const pathName = usePathname();
  return (
    <>
      <header
        className={`bg-slate-900 static hidden  ${
          pathName.includes('industry-reports') || pathName.includes('buynow')
            ? ''
            : 'lg:block'
        }`}
      >
        <div className="container-fluid">
          <div className=" flex flex-row p-2 items-center text-center text-white justify-between">
            <div className=" text-sm relative">
              <a
                href="mailto:sales@coherentmi.com"
                className=" mail-id mb-0"
                title="Mail Us"
              >
                {' '}
                <b>Sales Support :</b>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-envelope ms-2 inline-block"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                </svg>{' '}
                <span>sales@coherentmi.com</span>
              </a>
            </div>
            <div className="us-contact  relative ">
              <a
                className="mb-0 flex items-center text-center"
                href="tel:+1-650-918-5898"
                title="Call Us"
              >
                <US
                  title="United States"
                  height={25}
                  width={30}
                  className="me-2"
                />{' '}
                <b>U.S.:</b> +1-650-918-5898
              </a>
            </div>
            <div className="us-contact  relative ">
              <a
                href="tel:+44-020-8133-4027"
                className="mb-0 flex items-center text-center"
                title="Call Us"
              >
                <GB
                  title="United Kingdom"
                  height={25}
                  width={30}
                  className="me-2"
                />{' '}
                <b>U.K.:</b> +44-020-8133-4027
              </a>
            </div>
            <div className="us-contact  relative ">
              <a
                href="tel:+61-2-4786-0457"
                className="mb-0 flex items-center text-center"
                title="Call Us"
              >
                <AU title="Austatlia" height={25} width={30} className="me-2" />{' '}
                <b>AUSTRALIA:</b> +61-2-4786-0457
              </a>
            </div>
            <div className="us-contact  relative ">
              <a
                className="mb-0 flex items-center text-center"
                href="tel:+91-848-285-0837"
                title="Call Us"
              >
                <IN title="India" height={25} width={30} className="me-2" />{' '}
                <b>INDIA:</b> +91-848-285-083
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
