import Image from 'next/image';
import iso9001 from '../../../public/iso-9001-updated.webp';
import iso27001 from '../../../public/iso-27001-updated.webp';

export const IsoCerts = () => {
  return (
    <>
      <div className="flex flex-row gap-x-4 mt-1">
        <div className="text-center">
          <span className="text-white text-sm">9001:2015</span>
          <Image
            src={iso9001}
            className="img-fluid"
            alt="iso-9001"
            title="iso-9001"
            style={{
              width: '120px',
              height: 'auto',
            }}
          />
        </div>
        <div className="text-center">
          <span className="text-white text-sm">27001:2022</span>
          <Image
            src={iso27001}
            className="img-fluid"
            alt="iso-27001"
            title="iso-27001"
            style={{
              width: '120px',
              height: 'auto',
            }}
          />
        </div>
      </div>
    </>
  );
};
