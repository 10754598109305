'use client';

import Link from 'next/link';
import Image from 'next/image';
import Logo from '../../../public/logo.webp';
import { usePathname, useRouter } from 'next/navigation';
// import SearchBar from './search-bar';
import { useEffect, useState } from 'react';
import { HiOutlineMagnifyingGlass } from 'react-icons/hi2';
import { Disclosure } from '@headlessui/react';
import { FaXmark, FaBars } from 'react-icons/fa6';
import Smartlook from 'smartlook-client';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import { getDictionary } from '@/app/[lang]/dictionaries';
import buynowheader from '../../../public/buynowheader.webp';

const SearchBar = dynamic(() => import('./search-bar'));

export default function Navbar() {
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [navLinks, setNavLinks] = useState([
    {
      name: 'Home',
      href: '/',
    },
    {
      name: 'Latest Insights',
      href: '/latest-insights',
    },
    {
      name: 'Industries',
      href: '/industries',
    },
    {
      name: 'Blogs',
      href: '/blog',
    },
    {
      name: 'About Us',
      href: '/about-us',
    },
    {
      name: 'Contact Us',
      href: '/contact-us',
    },
  ]);
  const pathname = usePathname();

  let locales = ['en', 'fr', 'de', 'it', 'ru', 'ja', 'ar', 'zh', 'ko', 'pt'];

  const pathnameHasLocale = locales.some((locale) =>
    pathname.startsWith(`/${locale}/`)
  );

  useEffect(() => {
    Smartlook.init('ab3ef3063a9cb85ed3e0ef2f9a79244ff6f8fa82', {
      region: 'eu',
    });

    // const googleTranslateElementInit = () => {
    //   new window.google.translate.TranslateElement(
    //     {
    //       pageLanguage: 'en',
    //       includedLanguages: 'en,fr,de,it,ru,ja,zh-CN,ko,pt-PT',
    //       layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL,
    //       autoDisplay: true,
    //     },
    //     'google_translate_element'
    //   );
    // };

    // window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  function handleShow() {
    setShow(!show);
  }

  useEffect(() => {
    if (pathnameHasLocale) {
      const locale = pathname.split('/')[1];

      async function getDict() {
        const dict = await getDictionary(locale);

        const navBar = dict.navbar;
        //Updating the NavLinks with translated
        setNavLinks([
          {
            name: navBar.home,
            href: '/',
          },
          {
            name: navBar.latest_insights,
            href: '/latest-insights',
          },
          {
            name: navBar.industries,
            href: '/industries',
          },
          {
            name: navBar.blogs,
            href: '/blog',
          },
          {
            name: navBar.aboutus,
            href: '/about-us',
          },
          {
            name: navBar.contactus,
            href: '/contact-us',
          },
        ]);
      }

      getDict();
    } else {
      setNavLinks([
        {
          name: 'Home',
          href: '/',
        },
        {
          name: 'Latest Insights',
          href: '/latest-insights',
        },
        {
          name: 'Industries',
          href: '/industries',
        },
        {
          name: 'Blogs',
          href: '/blog',
        },
        {
          name: 'About Us',
          href: '/about-us',
        },
        {
          name: 'Contact Us',
          href: '/contact-us',
        },
      ]);
    }
  }, [pathname, pathnameHasLocale]);

  return (
    <Disclosure as="nav" className={`sticky top-0 z-20 bg-white shadow-md`}>
      {({ open }) => (
        <>
          {pathname.includes('buynow') ? (
            <Link href="/">
              <Image
                src={buynowheader}
                alt="Buy Now Header"
                className="w-full lg:h-16 h-8"
                title="CoherentMI"
                sizes="100vw"
                priority
              />
            </Link>
          ) : (
            <>
              <div className="container flex flex-row justify-between items-center relative">
                <div className=" md:flex-shrink">
                  <Link href="/">
                    <Image
                      src={Logo}
                      alt="CoherenMI Logo"
                      className="lg:w-62 lg:h-18 w-56 h-16"
                      title="CoherentMI"
                      sizes="100vw"
                      priority
                    />
                  </Link>
                </div>
                {/* {reportTitle !== '' ? (
                  <div>{reportTitle}</div>
                ) : ( */}
                <div className="flex relative items-center">
                  <ul
                    className={`text-center lg:flex lg:flex-row items-center hidden ${
                      pathname.includes('request-sample') ? 'invisible' : ''
                    }`}
                  >
                    {navLinks.map((link, index) => (
                      <li key={index} className=" text-center font-semibold">
                        <Link
                          href={link.href}
                          className={
                            pathname === link.href
                              ? 'text-blue-600 px-4 mb-3'
                              : 'px-3 mb-3'
                          }
                        >
                          {link.name}
                        </Link>
                        <div
                          className={
                            pathname === link.href
                              ? 'bg-blue-600 h-px w-full'
                              : 'hidden'
                          }
                        />
                      </li>
                    ))}
                  </ul>
                  {/* google translate Div */}
                  {/* <div
                  id="google_translate_element"
                  className={
                    pathnameHasLocale || pathname.includes('request-sample')
                      ? 'hidden'
                      : 'hidden sm:block'
                  }
                /> */}
                  <button
                    className={
                      show
                        ? `bg-orange-500 text-slate-900 rounded-full p-3 m-2 `
                        : `md:bg-gray-300 hover:bg-blue-500 text-slate-900 hover:text-white rounded-full p-3 m-2 bg-gradient-to-r from-orange-400 to-pink-500 z-10 ${
                            pathname.includes('request-sample')
                              ? 'invisible'
                              : ''
                          }`
                    }
                    onClick={() => setShow(true)}
                    aria-label="SearchButton"
                  >
                    <HiOutlineMagnifyingGlass
                      size={18}
                      className="text-white"
                    />
                  </button>
                  {/* Mobile menu button*/}
                  <div className="inset-y-0 right-0 lg:hidden items-center">
                    <Disclosure.Button
                      className="relative inline-flex items-center justify-center justify-items-center rounded-md p-2 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => {
                        setIsOpen(!isOpen);
                        setShow(false);
                      }}
                      aria-label="Menu Button"
                    >
                      {isOpen && open ? (
                        <FaXmark className="block h-8 w-8" aria-hidden="true" />
                      ) : (
                        <FaBars className="block h-8 w-8" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
                {/* )} */}
                {/* <Script
                  src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
                  strategy="lazyOnload"
                  defer={true}
                /> */}
              </div>
              <Disclosure.Panel
                className="lg:hidden mobile-menu shadow-xl"
                as="div"
              >
                {({ close }) => (
                  <>
                    <div className="space-y-1 px-2 pb-3 pt-2 flex flex-col gap-y-1 font-medium">
                      {navLinks.map((item) => (
                        <div key={item.name}>
                          <Link
                            href={item.href}
                            className={
                              pathname === item.href
                                ? 'text-blue-600 px-4'
                                : 'px-4'
                            }
                            aria-current={item.current ? 'page' : undefined}
                            onClick={() => {
                              close();
                            }}
                          >
                            {item.name}
                          </Link>
                          <div
                            className={
                              pathname === item.href
                                ? 'bg-blue-600 h-px w-full'
                                : 'hidden'
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </Disclosure.Panel>
              <SearchBar show={show} setShow={handleShow} />
            </>
          )}
        </>
      )}
    </Disclosure>
  );
}
